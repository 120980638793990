import type { ValidatedUser } from "@wind/auth";
import type { SuperAdminPermissions } from "@wind/permission";

import { useTypedRouteLoaderData } from "./useTypedRouteLoaderData.js";

export const useOptionalAuthUser = (): ValidatedUser<SuperAdminPermissions> | null => {
  const matches = useTypedRouteLoaderData("root");

  return matches.authUser;
};

export const useAuthUser = () => {
  const user = useOptionalAuthUser();
  if (!user) {
    throw new Error("No auth user found");
  }

  return user;
};
